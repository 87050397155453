import React from 'react';

import { Flex, Box, Image, Text, Stack, Alert, AlertIcon, HStack, Icon, Center } from '@chakra-ui/react';
import { LockIcon, InfoOutlineIcon } from '@chakra-ui/icons';

import { useParams } from 'react-router-dom';

import {
	PaymentLinksApi,
	GetPaymentLinkDto,
	GetSimulationDto,
	SimulationApi,
	ListSimulationRequest,
	UpdatePaymentLinkRequest,
	UpdatePaymentLinkDtoStatusEnum,
	GetPaymentLinkSaleDataResponseDto,
} from '../../clients';

import * as variables from '../../config/variables';

import { getApiDefaultConfig, parseExceptionMessage } from '../../services/api.service';
import { maskMoney, unmaskCpfOrCnpj } from '../../services/masks.service';
import { CheckoutSaleInformationType } from './types/checkout.type';
import { useTheme } from '../../contexts/ThemeProvider';

import TabsWrapper from './containers/TabsWrapper';
import Button from '../../components/Button';
import Loader from '../../containers/Loader';
import DownloadPaymentBillet from './components/DownloadPaymentBillet';
import { useToasts } from 'react-toast-notifications';

const PaymentLinkCheckout: React.FC = () => {
	const [isLoading, setIsLoading] = React.useState(true);
	const [paymentLinkData, setPaymentLinkData] = React.useState<GetPaymentLinkDto | undefined>(undefined);
	const [paymentLinkBilletUrlData, setPaymentLinkBilletUrlData] = React.useState<GetPaymentLinkSaleDataResponseDto | undefined>(undefined);
	const [simulationValues, setSimulationValues] = React.useState<Array<GetSimulationDto>>([]);
	const [saleInformation, setSaleInformation] = React.useState<CheckoutSaleInformationType | undefined>(undefined);
	const [linkPayed, setLinkPayed] = React.useState(false);
	const [linkExpiredPaid, setLinkExpiredPaid] = React.useState(false);

	const { token } = useParams();
	const { updateTheme } = useTheme();
	const { addToast } = useToasts();

	const apiConfig = getApiDefaultConfig();
	const paymentLinkApi = new PaymentLinksApi(apiConfig);
	const simulationApi = new SimulationApi(apiConfig);

	const fetchPaymentLinkData = async (filters = {}) => {
		setPaymentLinkData(undefined);
		setSimulationValues([]);

		try {
			setIsLoading(true);

			const response = await paymentLinkApi.getPaymentLinkByToken({ token });

			setPaymentLinkData(response);
			updateTheme(response.theme);

			const listSimulationRequest: ListSimulationRequest = {
				sellerId: response.seller.id,
				amountCents: response.service_value ?? response.amount,
				isPos: false,
			};

			const simulationResponse = await simulationApi.listSimulation(listSimulationRequest);

			setSimulationValues(simulationResponse);

			setSaleInformation({
				id: response.sale.id,
				description: response.description,
				first_name: response.payer_name.split(' ')[0],
				last_name: response.payer_name.split(' ').slice(1).join(' '),
				document: response.payer_document ? unmaskCpfOrCnpj(response.payer_document) : null,
				email: response.email,
			});
		} catch (e) {
			const errorMessage = await parseExceptionMessage(e, 'Link inválido');

			if (errorMessage === 'Paid link') {
				setLinkExpiredPaid(true);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const submitReceiptEmail = async () => {
		try {
			setIsLoading(true);
			await paymentLinkApi.sentPaymentLinkReceiptMail({ token });

			setLinkExpiredPaid(false);
			setLinkPayed(true);
		} finally {
			setIsLoading(false);
		}
	};
	const getBilletUrl = async () => {
		try {
			setIsLoading(true);
			if (token) {
				const paymentLinkUrl = await paymentLinkApi.getPaymentLinkBilletUrl({ token });
				setPaymentLinkBilletUrlData(paymentLinkUrl);
			}
		} catch (error) {
			addToast('Erro ao buscar o boleto', {
				appearance: 'error',
				autoDismiss: true,
			});
			setIsLoading(false);
		}
	};

	const validateExpirationDate = (expiresIn: string): boolean => {
		return new Date(expiresIn) > new Date();
	};

	React.useEffect(() => {
		fetchPaymentLinkData();
		getBilletUrl();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updatePaymentLink = async () => {
		if (!paymentLinkData) return;

		const requestParams: UpdatePaymentLinkRequest = {
			paymentLinkId: paymentLinkData.id,
			updatePaymentLinkDto: { expiresIn: true, status: UpdatePaymentLinkDtoStatusEnum.Expired },
		};

		await paymentLinkApi.updatePaymentLink(requestParams);
	};

	if ((!isLoading && !paymentLinkData && !linkExpiredPaid) || linkPayed) {
		return (
			<Flex w={`100%`} h={`100%`} justifyContent={`center`}>
				<Stack w={`40%`} spacing={3} mt={20}>
					<Flex minH={120} flexDir={`column`} alignItems={`center`}>
						<Image src={paymentLinkData?.logo.url || variables.logoUrl} w={130} />
					</Flex>
					<Flex flexDir={`column`}>
						{!linkPayed ? (
							<>
								{paymentLinkBilletUrlData?.billet_url && validateExpirationDate(paymentLinkBilletUrlData.billet_expires_in!) ? (
									<>
										<Flex justifyContent='center'>
											<Alert mt={10} width='80%' justifyContent='center' status='error'>
												<AlertIcon />
												<Text>O link já possui um boleto gerado, clique no botão abaixo para download.</Text>
											</Alert>
										</Flex>
										<Flex mt='4' alignItems='center' justifyContent='center'>
											<DownloadPaymentBillet billet_url={paymentLinkBilletUrlData.billet_url} />
										</Flex>
									</>
								) : (
									<Alert mt={10} status='error'>
										<AlertIcon />
										<Text>Link inválido.</Text>
									</Alert>
								)}
							</>
						) : (
							<>
								<Alert mt={10} status='success'>
									<AlertIcon />
									<Text>Operação efetuada com sucesso!</Text>
								</Alert>
							</>
						)}
					</Flex>
				</Stack>
			</Flex>
		);
	}

	if (linkExpiredPaid && !isLoading) {
		return (
			<Flex w={`100%`} h={`100%`} justifyContent={`center`}>
				<Stack w={`40%`} spacing={3} mt={20}>
					<Flex flexDir={`column`} alignItems={`center`}>
						<Image src={paymentLinkData?.logo.url} w={130} />
					</Flex>
					<Flex flexDir={`column`}>
						<Alert mt={10} status='success'>
							<AlertIcon />
							<Text>Pagamento já efetuado com sucesso!</Text>
						</Alert>
						<Flex justifyContent={`center`} mt={3}>
							<Button onClick={() => submitReceiptEmail()}>Reenviar recibo</Button>
						</Flex>
					</Flex>
				</Stack>
			</Flex>
		);
	}

	if (isLoading) {
		return <Loader isOpen={true} />;
	}
	return (
		<Flex flexDirection={`column`} w={`100%`} minHeight={`100vh`}>
			{!isLoading && paymentLinkData && simulationValues && saleInformation && (
				<Flex flexDirection={['column', 'row']} w={`100%`} minHeight={`100vh`}>
					<Flex
						flexDir={`column`}
						justifyContent={`space-between`}
						width={['100%', '45%']}
						minHeight={`100%`}
						backgroundColor={`primary`}
						color={`#fff`}
						align={['center', 'unset']}
						px={`4`}
						py={`6`}
					>
						<Flex flexDir={`column`} alignItems={`center`} justifyContent={`space-between`} mb={`2`}>
							<Image mb={['2', '4']} src={paymentLinkData?.logo.url || variables.logoUrl} w={150} />
							<Stack>
								<Center mb={`4`}>
									<Text fontSize='sm' fontWeight='semibold' align={['center', 'unset']}>
										Cobrança enviada por
										{` `}
										<Text as={`span`} textDecor={`underline`}>
											{paymentLinkData?.seller.description}
										</Text>
										{` `} referente ao valor{' '}
										{paymentLinkData?.seller?.custom_payment_link_checkout
											? 'do título, de emolumentos e outras despesas.'
											: 'de emolumentos e outras despesas relacionadas ao cartório.'}
									</Text>
								</Center>
								<Box mb={`2`} px={`14`} py={`4`} borderRadius={'0.5rem'} background={`blackAlpha.400`}>
									<Center>
										<Text fontSize={`xl`} fontWeight={`bold`} align={['center', 'unset']} className='sale-data__amount'>{`R$ ${maskMoney(
											(paymentLinkData.service_value ?? paymentLinkData!.amount) / 100
										)}`}</Text>
									</Center>
								</Box>
								<Box mb={`2`} py={['2', '4']} borderRadius={`md`} background={`blackAlpha.400`}>
									<Center>
										<Text display='block' fontSize='md' fontWeight='semibold' align='center' className='sale-data__description'>
											{paymentLinkData?.description}
										</Text>
									</Center>
								</Box>
								{paymentLinkData?.note && (
									<Box mb={`2`} p={['2', '4']} borderRadius={`md`} background={`blackAlpha.400`}>
										<Text display='block' fontSize='sm' fontWeight='medium' align='left' className='sale-data__note'>
											{paymentLinkData.note}
										</Text>
									</Box>
								)}
								{!paymentLinkData.seller.custom_payment_link_checkout && (
									<Box mb={`2`} px={['8', '12']} py={['4', '6']} borderRadius={`md`} background={`blackAlpha.400`}>
										<Stack direction={['column', 'column', 'column', 'row']} align='center' spacing={`4`}>
											<Icon w={`4`} as={InfoOutlineIcon} align={['center', 'unset']} />
											<Center>
												<Text display='block' fontSize='sm' align={['center', 'unset']}>
													Sobre o valor dos emolumentos informado será acrescentada a taxa de administração do cartão.
													<Text my='2' fontSize='sm' align={['center', 'unset']}>
														Preencha os dados ao lado para concluir o pagamento.
													</Text>
													<Text color={`gray.500`} fontSize='sm' align={['center', 'unset']}>
														Após o pagamento você terá acesso ao comprovante.
													</Text>
												</Text>
											</Center>
										</Stack>
									</Box>
								)}
							</Stack>
						</Flex>
						<Center>
							<HStack spacing={`2`}>
								<Icon color={`gray.500`} as={LockIcon} align={['center', 'unset']} />
								<Text color={`gray.500`} fontSize='sm' align={['center', 'unset']}>
									Movimentações amparadas pelo nosso sistema de segurança.
								</Text>
							</HStack>
						</Center>
					</Flex>
					<Flex flexDir={`column`} w='100%' h={`100%`} justifyContent={`space-between`} alignItems={`center`}>
						<Flex flexDir={`column`} w={['100%', '80%']} justifyContent={`center`} alignItems={`center`} mt={`8`}>
							<Center mb={`6`}>
								{paymentLinkData?.payment_methods && paymentLinkData?.payment_methods?.length > 1 && (
									<Text fontSize='lg' fontWeight='semibold' align={['center', 'unset']}>
										Escolha a forma de pagamento
									</Text>
								)}
							</Center>
							<Box w={['95%', '90%', '80%', '75%']}>
								<TabsWrapper
									seller={paymentLinkData!.seller}
									simulationValues={simulationValues}
									saleInformation={saleInformation}
									setLinkPayed={setLinkPayed}
									updatePaymentLink={updatePaymentLink}
									hasPreCapture={paymentLinkData.pre_capture}
									paymentLinkData={{
										billet_is_permitted: paymentLinkData?.billet_payment_is_permitted || false,
										qrcode_is_permitted: paymentLinkData?.qrcode_payment_is_permitted || false,
										card_is_permitted: paymentLinkData?.card_payment_is_permitted || false,
									}}
									paymentLink={paymentLinkData}
								/>
							</Box>
						</Flex>
						<Flex px='6' py='4' w='100%' justifyContent='flex-start'>
							<Text fontSize='xs' color={`gray.500`} align='left'>
								Pagamento processado pela Parcela Express Serviços Digitais SA. Em caso de dúvida, entre em contato pelo telefone: (31)
								3264-0337 ou consulte nosso Termo de Uso e Política de Privacidade.
							</Text>
						</Flex>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};

export default PaymentLinkCheckout;
